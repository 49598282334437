import React, { useState, useEffect } from "react";
import { db } from "../../../../util/firebaseConfig";
import "/Users/jaso/code/webdev/relic-client/src/App.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Button, Typography } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListTwoTone } from "@material-ui/icons";
import { Divider } from "@mui/material";
import {
  Star,
  StarBorderOutlined,
  StarBorderPurple500Outlined,
} from "@mui/icons-material";
const styles = {
  container: {
    maxHeight: 400,
    overflowX: "scroll",
  },
  root: {
    width: "100%",
    overflow: "auto",
  },
  icon: {
    verticalAlign: "bottom",
  },
};

const HomeGithub = (props) => {
  const [ghub, setGhub] = useState([]);
  const classes = styles;

  useEffect(() => {
    db.collection("github")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setGhub(data);
      });
  }, []);

  return (
    <Table className={props.table} aria-label="simple table">
      {ghub.map((loc, i) => (
        <TableBody>
          {loc.data.slice(0, 25).map((row, j) => (
            <TableRow key={j}>
              <TableCell align="left">
                <Typography variant="h4"  style={{ fontWeight: "bold",opacity:0.5 }}>
                  {row.index +1}{" "}
                </Typography>
                <Box
                  paddingLeft={7}
                  paddingRight={2}
                  paddingTop={0}
                  paddingBottom={2}
                >
                  <Typography variant="h6" style={{ fontWeight: "bold", opacity:0.8 }}>
                    <a href={row.url}>{row.name}</a>
                  </Typography>
                  <Divider />
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    {row.language}
                  </Typography>
                  <Divider />
                  <Typography variant="body1" className={styles.icon}>
                    ⭐️ {row.stars}
                  </Typography>
                  <Divider />
                  <Typography variant="body1" paragraph></Typography>
                  <Typography variant="body2">{row.description}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      ))}
    </Table>
  );
};

export default HomeGithub;
