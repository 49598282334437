import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import Profile from '../components/profile/Profile';
import Weather from '../components/trends/Weather';



class olympics extends Component {
  render() {
    return (
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12} >
          <Card style={{maxHeight:800}}>
            <CardContent>
            <h3 align="center">Weather</h3>
          <Weather/>
          </CardContent>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Profile />
        </Grid>
      </Grid>
    );
  }
}


export default olympics;