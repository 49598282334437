import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { db } from "../../util/firebaseConfig";


class Tally extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      olympics: [],
    };
  }

  componentDidMount() {
    db.collection("olympics")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        this.setState({ olympics: data });
      });
  }
  render() {

    const classes = this.props;
    const { olympics } = this.state;
    return (
      <Card className={classes.card} variant="outlined">
        {olympics.map((position, i) => (
          <CardContent className={classes.content}>
            <h2 align="center">Olympics Medal Rankings</h2>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{position.Position_0.Rank}</TableCell>
                  <TableCell>{position.Position_0.Country}</TableCell>
                  <TableCell>{position.Position_0.Gold}</TableCell>
                  <TableCell>{position.Position_0.Silver}</TableCell>
                  <TableCell>{position.Position_0.Bronze}</TableCell>
                  <TableCell>{position.Position_0.OMT}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{position.Position_1.Rank}</TableCell>
                  <TableCell>{position.Position_1.Country}</TableCell>
                  <TableCell align="center"l>{position.Position_1.Gold}</TableCell>
                  <TableCell align="center"l>{position.Position_1.Silver}</TableCell>
                  <TableCell align="center">{position.Position_1.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_1.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_2.Rank}</TableCell>
                  <TableCell>{position.Position_2.Country}</TableCell>
                  <TableCell align="center"l>{position.Position_2.Gold}</TableCell>
                  <TableCell align="center"l>{position.Position_2.Silver}</TableCell>
                  <TableCell align="center">{position.Position_2.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_2.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_3.Rank}</TableCell>
                  <TableCell>{position.Position_3.Country}</TableCell>
                  <TableCell align="center"l>{position.Position_3.Gold}</TableCell>
                  <TableCell align="center"l>{position.Position_3.Silver}</TableCell>
                  <TableCell align="center">{position.Position_3.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_3.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_4.Rank}</TableCell>
                  <TableCell>{position.Position_4.Country}</TableCell>
                  <TableCell align="center"l>{position.Position_4.Gold}</TableCell>
                  <TableCell align="center"l>{position.Position_4.Silver}</TableCell>
                  <TableCell align="center">{position.Position_4.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_4.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_5.Rank}</TableCell>
                  <TableCell>{position.Position_5.Country}</TableCell>
                  <TableCell align="center"l>{position.Position_5.Gold}</TableCell>
                  <TableCell align="center"l>{position.Position_5.Silver}</TableCell>
                  <TableCell align="center">{position.Position_5.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_5.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_6.Rank}</TableCell>
                  <TableCell>{position.Position_6.Country}</TableCell>
                  <TableCell align="center"l>{position.Position_6.Gold}</TableCell>
                  <TableCell align="center"l>{position.Position_6.Silver}</TableCell>
                  <TableCell align="center">{position.Position_6.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_6.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_7.Rank}</TableCell>
                  <TableCell>{position.Position_7.Country}</TableCell>
                  <TableCell align="center"l>{position.Position_7.Gold}</TableCell>
                  <TableCell align="center"l>{position.Position_7.Silver}</TableCell>
                  <TableCell align="center">{position.Position_7.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_7.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_8.Rank}</TableCell>
                  <TableCell>{position.Position_8.Country}</TableCell>
                  <TableCell align="center"l>{position.Position_8.Gold}</TableCell>
                  <TableCell align="center"l>{position.Position_8.Silver}</TableCell>
                  <TableCell align="center">{position.Position_8.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_8.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_9.Rank}</TableCell>
                  <TableCell>{position.Position_9.Country}</TableCell>
                  <TableCell align="center"l>{position.Position_9.Gold}</TableCell>
                  <TableCell align="center"l>{position.Position_9.Silver}</TableCell>
                  <TableCell align="center">{position.Position_9.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_9.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_10.Rank}</TableCell>
                  <TableCell>{position.Position_10.Country}</TableCell>
                  <TableCell align="center">{position.Position_10.Gold}</TableCell>
                  <TableCell align="center">{position.Position_10.Silver}</TableCell>
                  <TableCell align="center">{position.Position_10.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_10.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_11.Rank}</TableCell>
                  <TableCell>{position.Position_11.Country}</TableCell>
                  <TableCell align="center">{position.Position_11.Gold}</TableCell>
                  <TableCell align="center">{position.Position_11.Silver}</TableCell>
                  <TableCell align="center">{position.Position_11.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_11.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_12.Rank}</TableCell>
                  <TableCell>{position.Position_12.Country}</TableCell>
                  <TableCell align="center">{position.Position_12.Gold}</TableCell>
                  <TableCell align="center">{position.Position_12.Silver}</TableCell>
                  <TableCell align="center">{position.Position_12.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_12.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_13.Rank}</TableCell>
                  <TableCell>{position.Position_13.Country}</TableCell>
                  <TableCell align="center">{position.Position_13.Gold}</TableCell>
                  <TableCell align="center">{position.Position_13.Silver}</TableCell>
                  <TableCell align="center">{position.Position_13.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_13.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_14.Rank}</TableCell>
                  <TableCell>{position.Position_14.Country}</TableCell>
                  <TableCell align="center">{position.Position_14.Gold}</TableCell>
                  <TableCell align="center">{position.Position_14.Silver}</TableCell>
                  <TableCell align="center">{position.Position_14.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_14.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_15.Rank}</TableCell>
                  <TableCell>{position.Position_15.Country}</TableCell>
                  <TableCell align="center">{position.Position_15.Gold}</TableCell>
                  <TableCell align="center">{position.Position_15.Silver}</TableCell>
                  <TableCell align="center">{position.Position_15.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_15.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_16.Rank}</TableCell>
                  <TableCell>{position.Position_16.Country}</TableCell>
                  <TableCell align="center">{position.Position_16.Gold}</TableCell>
                  <TableCell align="center">{position.Position_16.Silver}</TableCell>
                  <TableCell align="center">{position.Position_16.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_16.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_17.Rank}</TableCell>
                  <TableCell>{position.Position_17.Country}</TableCell>
                  <TableCell align="center">{position.Position_17.Gold}</TableCell>
                  <TableCell align="center">{position.Position_17.Silver}</TableCell>
                  <TableCell align="center">{position.Position_17.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_17.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_18.Rank}</TableCell>
                  <TableCell>{position.Position_18.Country}</TableCell>
                  <TableCell align="center">{position.Position_18.Gold}</TableCell>
                  <TableCell align="center">{position.Position_18.Silver}</TableCell>
                  <TableCell align="center">{position.Position_18.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_18.OMT}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{position.Position_19.Rank}</TableCell>
                  <TableCell>{position.Position_19.Country}</TableCell>
                  <TableCell align="center">{position.Position_19.Gold}</TableCell>
                  <TableCell align="center">{position.Position_19.Silver}</TableCell>
                  <TableCell align="center">{position.Position_19.Bronze}</TableCell>
                  <TableCell align="center">{position.Position_19.OMT}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        ))}
      </Card>
    );
  }
}

export default Tally;
