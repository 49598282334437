import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Text from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState, useEffect } from "react";

import { db } from "../util/firebaseConfig";

const useStyles = makeStyles((theme) => ({
  creators: {
    fontSize: "x-small",
  },
  source: {
    fontSize: "8px",
  },
  abstract: {
    fontSize: "small",
  },
  titleLink: {
    variant: "subtitle2",
  },
}));

const Dev = () => {
  const [arx_trend, setArxTrend] = useState([]);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    db.collection("arx_")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setArxTrend(data);
      });
  }, []);

  return (
    <div>
      {arx_trend.map((loc, i) => (
        <Table
          className={classes.table}
          aria-label="simple table"
          style={{ borderColor: "white", borderBottom: "none" }}
        >
          <TableBody style={{ borderColor: "white", borderBottom: "none" }}>
            {loc.data.slice(0, 20).map((row, j) => (
              <Accordion
                disableGutters
                elevation={0}
                square
                expanded={expanded === `panel${j}`}
                onChange={handleChange(`panel${j}`)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <TableCell
                    style={{ borderColor: "white", borderBottom: "none" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h4"
                        style={{
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginRight: "10px",
                        }}
                      >
                        {row.index + 1}{" "}
                      </Typography>
                    </div>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", opacity: 0.9 }}
                    >
                      {row.title}
                    </Typography>
                  </TableCell>
                </AccordionSummary>
                <AccordionDetails>
                  <Table style={{ borderColor: "white", borderBottom: "none" }}>
                    <TableRow>
                      <TableCell>
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          Summary
                        </Text>
                      </TableCell>

                      <TableCell>
                        <Text style={{ fontSize: 12 }}>{row.summary}</Text>
                      </TableCell>
                    </TableRow>
                    <TableRow key={j}>
                      <TableCell>
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          URL
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text style={{ fontSize: 12 }}>
                          <a href={row.link} target="_blank" rel="noreferrer">
                            {row.link}
                          </a>
                        </Text>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          Authors
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text style={{ fontSize: 12 }}>{row.author}</Text>
                      </TableCell>
                    </TableRow>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  );
};

export default Dev;
