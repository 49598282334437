
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Card,
  CardContent,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { db } from "../../util/firebaseConfig";
import firebase from "firebase/app";

const getDayOfWeekColor = () => {
  const date = new Date();
  const day = date.getDay();
  const colors = [
    "#F44336", // Sunday
    "#E91E63", // Monday
    "#9C27B0", // Tuesday
    "#673AB7", // Wednesday
    "#3F51B5", // Thursday
    "#2196F3", // Friday
    "#03A9F4", // Saturday
  ];
  return colors[day];
};




const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      backgroundColor: "#f7efbe", // Add this line
    },
    card: {
      width: "100%",
      margin: theme.spacing(1),
    },
    form: {

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: theme.palette.primary,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },
    textField: {
      marginBottom: theme.spacing(2),
      color: "white",
      width: "100%",
    },
    input: {
        width: "100%",
        padding: theme.spacing(2),
        border: "none",
        borderRadius: theme.shape.borderRadius,
        fontSize: "1rem",
      },
    button: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      width: "100%",
      marginTop: theme.spacing(2),
    },
    error: {
      color: "red",
      marginTop: theme.spacing(1),
    },
  }));

const Wall = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState("");
  const classes = useStyles();

  useEffect(() => {
    const unsubscribe = db
      .collection("messages")
      .orderBy("timestamp", "desc") // Order messages by timestamp, newest to oldest
      .onSnapshot((snapshot) => {
        setMessages(
          snapshot.docs
            .map((doc) => doc.data())
            .filter((message) => message.timestamp) // Filter out messages without a timestamp
        );
      });
    return () => unsubscribe();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.length <= 2500) {
      const timestamp = firebase.firestore.FieldValue.serverTimestamp(); // Get the current server timestamp
      db.collection("messages").add({ message, timestamp });
      setMessages([...messages, { message, timestamp }]);
      setMessage("");
      setError("");
    } else {
      setError("The message must be 2500 characters or less.");
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card} >
        <CardContent>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              label="Write your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={classes.textField}
              multiline
              rows={3}
              rowsMax={20}
            />
            {error && <p className={classes.error}>{error}
            </p>}
            <Button type="submit" className={classes.button}>
              Post
            </Button>
          </form>
        </CardContent>
      </Card>
      {messages.map((message) => (
        <Card key={message.timestamp} className={classes.card}>
          <CardContent>
            <p>{message.message}</p>
            <small style={{ float: "right" }}>
              {message.timestamp?.toDate().toLocaleString()}
            </small>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default Wall;