import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Text from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { db } from "../../util/firebaseConfig";
import "/Users/jaso/code/webdev/relic-client/src/App.css";

const styles = {
  container: {
    maxHeight: 400,
    overflowX: "scroll",
  },
  root: {
    width: "100%",
    overflow: "auto",
  },
};

class TV_Stream extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tvshows: [],
    };
  }

  componentDidMount() {
    db.collection("tv_streamers")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ tvshows: data });
      });
  }
  render() {
    const classes = this.props;
    const { tvshows } = this.state;
    return (
      <Card className={classes.card} variant="outlined">
        {tvshows.map((loc, i) => (
          <CardContent className={classes.content} style={{ overflow: "auto" }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>TV Shows</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loc.data.map((row, j) => (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <TableCell>
                        <b>{row.title}</b>
                      </TableCell>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableRow key={j}>
                          <TableCell>
                            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                              Overview
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text style={{ fontSize: 12 }}>{row.overview}</Text>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                              Stars
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text style={{ fontSize: 12 }}>{row.stars}</Text>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                              Crew
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text style={{ fontSize: 12 }}>{row.crew}</Text>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                              Providers
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text style={{ fontSize: 12 }}>
                              {row.providers}
                            </Text>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <TableRow>
                  <TableCell>
                    <a
                      href="https://www.themoviedb.org/"
                      style={{ fontWeight: "normal" }}
                    >
                      {" "}
                      Source{" "}
                    </a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        ))}
      </Card>
    );
  }
}

export default TV_Stream;
