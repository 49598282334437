import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ShopIcon from '@material-ui/icons/Shop';
import React from 'react';
import { db } from '../../util/firebaseConfig';


class PlayStoreFree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          playStoreFree: []
        };
      }

componentDidMount() {
    db.collection("playStoreFree")
    .get()
    .then(querySnapshot =>{
        const data = querySnapshot.docs.map(doc => doc.data());
        console.log(data);
        this.setState({ playStoreFree: data })
    });
}
render() {

    const classes = this.props;
    const { playStoreFree } = this.state
    return (
        <Card className={classes.card}>
            {playStoreFree.map((rank) => (
                <CardContent className = {classes.content}>
                    <h4 className={classes.content} align="center"><ShopIcon/> Play Store Free</h4>
                    <p></p>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>{rank.Top.a1}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a2}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a3}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a4}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a5}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a6}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a7}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a8}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a9}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a10}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a11}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a12}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a13}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a14}</TableCell>                                
                            </TableRow>
                            <TableRow>
                                <TableCell>{rank.Top.a15}</TableCell>                                
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            ))}
        </Card>
    )
}
}

export default PlayStoreFree


