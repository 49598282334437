import React, { useState, useEffect } from "react";
import { db } from "../../../../util/firebaseConfig";
import "/Users/jaso/code/webdev/relic-client/src/App.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";


const styles = {
  container: {
    maxHeight: 400,
    overflowX: "scroll",
  },
  root: {
    width: "100%",
    overflow: "auto",
  },
};

const HomeSpotify = (props) => {
  const [weather, setWeather] = useState([]);

  useEffect(() => {
    db.collection("chart_spotify")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setWeather(data);
      });
  }, []);

  const classes = props;
  return (
    <Card className={classes.card} variant="outlined">
          {weather.map((loc, i) => (
           <CardContent className={classes.content} style={{overflow:"auto"}}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">Artist</TableCell>
                  <TableCell align="center">Track</TableCell>
                  <TableCell align="center">Weekly Streams</TableCell>
                  <TableCell align="center">Total Streams</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loc.data.slice(0,10).map((row,j) => (
                <TableRow>
                  <TableCell align="center">{row.pos}</TableCell>
                  <TableCell align="center"><a href={row.artist_url}>{row.artist_name}</a></TableCell>
                  <TableCell align="center"><a href={row.track_url}>{row.track_name}</a></TableCell>
                  <TableCell align="center">{row.streams}</TableCell>
                  <TableCell align="center">{row.total}</TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
            </CardContent> 
          ))}
      </Card>
  );
};

export default HomeSpotify;