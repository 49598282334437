import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { db } from '../../util/firebaseConfig';

class Planet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          planets: []
        };
      }

componentDidMount() {
    db.collection("planets")
    .get()
    .then(querySnapshot =>{
        const data = querySnapshot.docs.map(doc => doc.data());
        console.log(data);
        this.setState({ planets: data })
    });
}
render() {

    const classes = this.props;
    const { planets } = this.state
    return (
        <Card className={classes.card} variant="outlined">
            {planets.map((planet,i) => (
                <CardContent className = {classes.content}>
                    <h2 align="center">Gravity</h2>
                    <h5 align="center">Are the planets increasing or decreasing their gravitational effect upon you?</h5>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Planet</TableCell>
                                <TableCell align="center">∆ Gravity(24hr)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>SUN</TableCell>
                                <TableCell align="center">{planet.SUN}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>MERCURY</TableCell>
                                <TableCell align="center">{planet.MERCURY}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>VENUS</TableCell>
                                <TableCell align="center">{planet.VENUS}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>MOON</TableCell>
                                <TableCell align="center">{planet.MOON}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>MARS</TableCell>
                                <TableCell align="center">{planet.MARS}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>JUPITER</TableCell>
                                <TableCell align="center">{planet.JUPITER}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>SATURN</TableCell>
                                <TableCell align="center">{planet.SATURN}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>URANUS</TableCell>
                                <TableCell align="center">{planet.URANUS}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>NEPTUNE</TableCell>
                                <TableCell align="center">{planet.NEPTUNE}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>PLUTO</TableCell>
                                <TableCell align="center">{planet.PLUTO}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            ))}
        </Card>
    )
}
}

export default Planet


