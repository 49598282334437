import React, { useState } from 'react';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { DarkMode } from '@mui/icons-material';
import { useCookies } from 'react-cookie';

const themeObject = {
  palette: {
    type: 'light',
  },
};

const theme = createTheme(themeObject);

const InvertColors = () => {
  const [cookies, setCookie] = useCookies(['invert']);
  const [invert, setInvert] = useState(cookies.invert || false);

  const handleClick = () => {
    setInvert(!invert);
    setCookie('invert', !invert, { path: '/' });
    const css = invert
      ? 'html {-webkit-filter: invert(0%); -moz-filter: invert(0%); -o-filter: invert(0%); -ms-filter: invert(0%); }'
      : 'html {-webkit-filter: invert(100%); -moz-filter: invert(100%); -o-filter: invert(100%); -ms-filter: invert(100%); }';

    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
  };

  return (
    <ThemeProvider theme={theme}>
      <Button onClick={handleClick}><DarkMode/></Button>
    </ThemeProvider>
  );
};

export default InvertColors
