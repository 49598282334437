import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { db } from "../../util/firebaseConfig";
import "/Users/jaso/code/webdev/relic-client/src/App.css";

const useStyles = makeStyles({
  container: {
    maxHeight: 400,
    overflowX: "scroll",
  },
  root: {
    width: "100%",
    overflow: "auto",
  },
  button: {
    backgroundColor: "#3f51b5",
    color: "white",
    padding: "5px 10px",
  },
});

function GHub(props) {
  const [selectedDatabase, setSelectedDatabase] = useState("github");
  const [data, setData] = useState([]);
    const classes = useStyles();
  useEffect(() => {
    db.collection(selectedDatabase)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setData(data);
      });
  }, [selectedDatabase]);

  function handleDatabaseChange(event) {
    setSelectedDatabase(event.target.value);
  }

  return (
    <div>
      <button
        value="github"
        onClick={handleDatabaseChange}
        className={classes.button}
      >
        GitHub
      </button>
      <button
        value="g_c"
        onClick={handleDatabaseChange}
        className={classes.button}
      >
        C
      </button>
      <button
        value="g_go"
        onClick={handleDatabaseChange}
        className={classes.button}
      >
        Go
      </button>
      <button
        value="g_javascript"
        onClick={handleDatabaseChange}
        className={classes.button}
      >
        JavaScript
      </button>
      <button
        value="g_python"
        onClick={handleDatabaseChange}
        className={classes.button}
      >
        Python
      </button>
      <button
        value="g_rust"
        onClick={handleDatabaseChange}
        className={classes.button}
      >
        Rust
      </button>
      <button
        value="g_swift"
        onClick={handleDatabaseChange}
        className={classes.button}
      >
        Swift
      </button>
      <Card className={props.card} variant="outlined">
        {data.map((loc, i) => (
          <CardContent className={props.content} style={{ overflow: "auto" }}>
            <Table className={props.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Repo</TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell align="center">Language</TableCell>
                  <TableCell align="center">Stars</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loc.data.map((row, j) => (
                  <TableRow key={j}>
                    <TableCell align="center">
                      <a href={row.url}>{row.name}</a>
                    </TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                    <TableCell align="center">{row.language}</TableCell>
                    <TableCell align="center">{row.stars}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        ))}
      </Card>
    </div>
  );
}

export default GHub;
