import {
    SET_DIGITS,
    LIKE_DIGIT,
    UNLIKE_DIGIT,
    LOADING_DATA,
    DELETE_DIGIT,
    POST_DIGIT,
    SET_DIGIT,
    SUBMIT_COMMENT
  } from '../types';
  
  const initialState = {
    digits: [],
    digit: {},
    loading: false
  };
  
  export default function dataReducer(state = initialState, action) {
    switch (action.type) {
      case LOADING_DATA:
        return {
          ...state,
          loading: true
        };
      case SET_DIGITS:
        return {
          ...state,
          digits: action.payload,
          loading: false
        };
      case SET_DIGIT:
        return {
          ...state,
          digit: action.payload
        };
      case LIKE_DIGIT:
      case UNLIKE_DIGIT:
        let index = state.digits.findIndex(
          (digit) => digit.digitId === action.payload.digitId
        );
        state.digits[index] = action.payload;
        if (state.digit.digitId === action.payload.digitId) {
          state.digit = action.payload;
        }
        return {
          ...state
        };
      case DELETE_DIGIT:
        index = state.digits.findIndex(
          (digit) => digit.digitId === action.payload
        );
        state.digits.splice(index, 1);
        return {
          ...state
        };
      case POST_DIGIT:
        return {
          ...state,
          digits: [action.payload, ...state.digits]
        };
      case SUBMIT_COMMENT:
        return {
          ...state,
          digit: {
            ...state.digit,
            comments: [action.payload, ...state.digit.comments]
          }
        };
      default:
        return state;
    }
  }