import React, { Component } from 'react';
import { KeyboardArrowUpTwoTone } from '@mui/icons-material';

class ScrollToTop extends Component {
  state = {
    showButton: false,
  };

  handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.pageYOffset > 100) {
      this.setState({ showButton: true });
    } else {
      this.setState({ showButton: false });
    }
  };

  render() {
    return (
      <button className={`scroll-to-top-btn ${this.state.showButton ? 'show' : 'hide'}`} onClick={this.handleClick}>
        <KeyboardArrowUpTwoTone />
      </button>
    );
  }
}

export default ScrollToTop;
