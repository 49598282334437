import React, { useState, useEffect } from "react";
import { db } from "../../../../util/firebaseConfig";
import "/Users/jaso/code/webdev/relic-client/src/App.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Text from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@mui/material";

const styles = {
  container: {
    maxHeight: 400,
    overflowX: "scroll",
  },
  root: {
    width: "100%",
    overflow: "auto",
  },
  credits: {
    fontSize: 2,
  },
};

const HomeMov_Stream = (props) => {
  const [movies, setMovies] = useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    db.collection("mov_streamers")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setMovies(data);
      });
  }, []);

  const classes = props;
  return (
    <div>
      {movies.map((loc, i) => (
          <Table className={classes.table} aria-label="simple table" style={{borderColor:'white',borderBottom:'none'}}>
            <TableBody style={{borderColor:'white',borderBottom:'none'}}>
              {loc.data.slice(0,10).map((row, j) => (
                <Accordion disableGutters elevation={0} square
                expanded={expanded === `panel${j}`}
                onChange={handleChange(`panel${j}`)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <TableCell style={{borderColor:'white',borderBottom:'none'}}>
                      <Typography variant="h4" style={{ fontWeight: "bold",opacity:0.5 }}>{row.index +1} </Typography>
                      <Typography variant="h6" style={{ fontWeight: "bold",opacity:0.9 }}>{row.title}</Typography>
                    </TableCell>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table style={{borderColor:'white',borderBottom:'none'}}>
                      <TableRow key={j}>
                        <TableCell>
                          <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                            Overview
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text style={{ fontSize: 12 }}>{row.overview}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                            Stars
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text style={{ fontSize: 12 }}>{row.stars}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                            Crew
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text style={{ fontSize: 12 }}>{row.crew}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                            Providers
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text style={{ fontSize: 12 }}>{row.providers}</Text>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              ))}
            </TableBody>
          </Table>
      ))}
    </div>
  );
};


export default HomeMov_Stream;
