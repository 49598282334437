import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Wall from "./wall";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Card, CardContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(2),
    
  },
  container: {
    width: "80%",
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    backgroundColor: "#f7efbe"
  },
  accordion: {
    marginTop: theme.spacing(2),
    width: "auto",
    border: `0.1px solid`,
    boxShadow: 'none',
  },
}));

const WallContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <h1>Mural</h1>
        <p>The mural will be cleaned every month or so.</p>
        <Accordion disableGutters className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Details</Typography>
        </AccordionSummary>
        <Card >
          <CardContent>
          <small>- There is no data kept about origin of posts - only the time they are posted.</small>
          <br />
          <small>- Posts are not moderated.</small>
          <br />
          <small>- Posts are max 2500 words.</small>
          <br />
          <small>
            - Feel free to add your identity to the post if you want.
          </small>
          </CardContent>
        </Card>
      </Accordion>
      <br></br>
      <div className={classes.container}>
        <Wall />
      </div>
    </div>
  );
};

export default WallContainer;
