import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { db } from "../../util/firebaseConfig";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nugits: [],
    };
  }

  componentDidMount() {
    db.collection("nugits")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        this.setState({ nugits: data });
      });
  }
  render() {
    const classes = this.props;
    const { nugits } = this.state;
    return (
      <Card className={classes.card}>
        {nugits.map((nugit, i) => (
          <CardContent className={classes.content}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Trend</TableCell>
                  <TableCell align="center">Number of Countries</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.one.trend}`
                      }
                    >
                      {nugit.one.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.one.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.two.trend}`
                      }
                    >
                      {nugit.two.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.two.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.three.trend}`
                      }
                    >
                      {nugit.three.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.three.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.four.trend}`
                      }
                    >
                      {nugit.four.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.four.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.five.trend}`
                      }
                    >
                      {nugit.five.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.five.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.six.trend}`
                      }
                    >
                      {nugit.six.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.six.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.seven.trend}`
                      }
                    >
                      {nugit.seven.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.seven.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.eight.trend}`
                      }
                    >
                      {nugit.eight.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.eight.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.nine.trend}`
                      }
                    >
                      {nugit.nine.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.nine.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.ten.trend}`
                      }
                    >
                      {nugit.ten.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.ten.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.eleven.trend}`
                      }
                    >
                      {nugit.eleven.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.eleven.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.twelve.trend}`
                      }
                    >
                      {nugit.twelve.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.twelve.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.thirteen.trend}`
                      }
                    >
                      {nugit.thirteen.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.thirteen.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.fourteen.trend}`
                      }
                    >
                      {nugit.fourteen.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.fourteen.count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={
                        "https://news.google.com/search?q=" +
                        `${nugit.fifteen.trend}`
                      }
                    >
                      {nugit.fifteen.trend}
                    </a>
                  </TableCell>
                  <TableCell align="center">{nugit.fifteen.count}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        ))}
      </Card>
    );
  }
}

export default News;
