import { Pinecone } from "@pinecone-database/pinecone";
import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMoreSharp } from "@mui/icons-material";
import { Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import React from "react";

const Search = () => {
  const [searchString, setSearchString] = useState("");
  const [searchEmbedding, setSearchEmbedding] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function convertUrlsToLinks(text) {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
      return (
        '<a href="' +
        url +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        "</a>"
      );
    });
  }
  function formatEmbeddingString(embeddingString) {
    // Split the string on each "|" and join with a newline character
    return embeddingString.split("|").join("\n\n");
  }

  const getEmbeddings = async () => {
    const url = "https://api.openai.com/v1/embeddings";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
    };
    const data = {
      input: searchString,
      model: "text-embedding-ada-002",
    };
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const json = await response.json();
    const embedding = json.data[0].embedding;
    setSearchEmbedding(embedding);
    return embedding;
  };

  const query2 = async (embedding) => {
    const url = "https://relic-66s5yim.svc.apw5-4e34-81fa.pinecone.io/query";
    const headers = {
      "Content-Type": "application/json",
      //cors

      "Api-Key": `${process.env.REACT_APP_PINECONE_API_KEY}`,
    };
    const data = {
      namespace: "relic",
      vector: embedding,
      top_k: 20,
      include_values: true,
      include_metadata: true,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const json = await response.json();
    const results = json.matches;
    const uniqueResults = results
      .reduce((acc, current) => {
        const x = acc.find(
          (item) => item.metadata.topic === current.metadata.topic
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, [])
      .slice(0, 5); // Take only top 5 unique items
    setSearchResults(uniqueResults);
  };

  function formatDate(dtCreated) {
    const dtString = dtCreated.toString();

    const year = dtString.substring(0, 4);
    const month = dtString.substring(4, 6) - 1; // Month is 0-indexed in JavaScript Date
    const day = dtString.substring(6, 8);
    const hours = dtString.substring(8, 10);
    const minutes = dtString.substring(10, 12);
    const seconds = dtString.substring(12, 14);

    const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

    // Define options for toLocaleDateString
    const options = {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: "UTC",
    };
    return date.toLocaleDateString("en-US", options);
  }

  const performSearch = async () => {
    setIsLoading(true);
    const embedding = await getEmbeddings();
    await query2(embedding);
    setIsLoading(false);
  };

  return (
    <div>
      <Typography
        variant="h3"
        color="secondary"
        style={{
          textAlign: "left",
          padding: "10px",
        }}
      >
        archive search
      </Typography>
      <div
        style={{
          //center the search bar
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TextField
          autoFocus // Add this line
          variant="outlined"
          label="Search"
          type="text"
          fullWidth
          onChange={(e) => setSearchString(e.target.value)}
          onKeyPress={async (e) => {
            if (e.key === "Enter") {
              performSearch();
            }
          }}
        />
        <Button
          onClick={performSearch}
          disabled={isLoading}
          variant="contained"
          color="secondary"
          style={{ padding: "5px" }}
        >
          {isLoading ? <HourglassBottomIcon /> : <SearchIcon />}
        </Button>
        {/* <p>{searchString}</p> */}
      </div>
      <br style={{ paddingTop: "5px" }}></br>
      <Divider />
      <div style={{ padding: "5px" }}>
        {searchResults.map((result, index) => {
          const processedText = convertUrlsToLinks(
            result.metadata.embedding_string
          );
          const newlineProcessedText = formatEmbeddingString(processedText);
          return (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreSharp />}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="body2" style={{ paddingTop: "5px" }}>
                    ::: {result.metadata.category} :::
                  </Typography>
                  <Typography variant="h6">{result.metadata.topic}</Typography>

                  <Typography variant="body2" style={{ paddingTop: "5px" }}>
                    {formatDate(result.metadata.dt_created)}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontFamily: "monospace",
                    fontSize: "0.8rem",
                    maxWidth: "100%", // Ensure the max width is 100% of its parent
                  }}
                  dangerouslySetInnerHTML={{ __html: newlineProcessedText }}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default Search;
