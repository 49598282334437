import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { db } from "../../util/firebaseConfig";

const styles = {
  header: {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  subheader: {
    fontSize: "0.3rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  card: {
    marginBottom: 20,
  },
};
class RedditNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reddit: [],
    };
  }

  componentDidMount() {
    db.collection("reddit")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        this.setState({ reddit: data });
      });
  }
  render() {
    const classes = this.props;
    const { reddit } = this.state;
    return (
      <Card className={classes.card} variant="outlined">
        {reddit.map((loc, i) => (
          <CardContent className={classes.content}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "medium", fontStyle: "bold" }}>
                    Articles
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.content}>
                {loc.data.map((row, j) => (
                <TableRow key={j}>
                  <TableCell
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      wordWrap: "break-word",
                      fontSize: "small",
                      flexWrap: "wrap",
                    }}
                  >
                    <a href={row.url}>{row.title}</a>
                    <p>Top Comments: </p>
                    <p>1. {row.comment1}</p>
                    <p>2. {row.comment2}</p>
                    <p>3. {row.comment3}</p>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        ))}
      </Card>
    );
  }
}

export default RedditNews;
