import {
    Button,
    Card,
    CardContent,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { BarChartRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { db } from "../../util/firebaseConfig";

const Aqi = () => {
  const [aqi, setAqi] = useState([]);

  useEffect(() => {
    db.collection("aqi")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setAqi(data);
      });
  }, []);

  return (
    //there are two point of data for each row - city and aqi - map over the array of objects
    <div>
        <Card>
            <CardContent>
      <Typography variant="h3" bold style={{ opacity: 0.5 }}>
        AIR QUALITY INDEX
      </Typography>

      {aqi.map((loc, i) => (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h5" style={{ opacity: 0.5 }}>
                  City
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Button variant="text" align="center" >
                  <Select IconComponent={BarChartRounded} align="center">
                    <MenuItem
                      style={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        wordWrap: "break-word",
                        fontSize: "small",
                        flexWrap: "wrap",
                      }}
                    >
                      <Card>
                        <CardContent>
                          <Typography variant="h6" style={{ opacity: 0.7 }}>
                            Air Quality
                          </Typography>
                          <Table size="small" aria-label="a dense table">
                            <TableRow>
                              <TableCell>0-50</TableCell>
                              <TableCell>Good</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>51-100</TableCell>
                              <TableCell>Moderate</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>101-150</TableCell>
                              <TableCell>
                                Unhealthy for Sensitive Groups
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>151-200</TableCell>
                              <TableCell>Unhealthy</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>201-300</TableCell>
                              <TableCell>Very Unhealthy</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>301-500</TableCell>
                              <TableCell>Hazardous</TableCell>
                            </TableRow>
                          </Table>
                        </CardContent>
                      </Card>
                    </MenuItem>
                  </Select>
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loc.data.map((row, j) => (
              <TableRow key={j}>
                <TableCell>
                  <Typography>{row.city}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{row.aqi}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      ))}
        </CardContent>
        </Card> 
    </div>
  );
};

export default Aqi;