import { TableHead } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Text from "@material-ui/core/Typography";
import { ExpandMoreOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { db } from "../../util/firebaseConfig";

const useStyles = makeStyles((theme) => ({
  creators: {
    fontSize: "x-small",
  },
  source: {
    fontSize: "8px",
  },
  abstract: {
    fontSize: "small",
  },
  titleLink: {
    variant: "subtitle2",
  },
}));

const Politics = () => {
  const [politics_trend, setPolTrend] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    db.collection("politics_trend")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setPolTrend(data);
      });
  }, []);

  return (
    <Card>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell> Papers
              <a href="https://www.nature.com/subjects/politics.atom" className={classes.source}> (Source) </a>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {politics_trend.map((trend, i) => (
            <TableRow key={i}>
              <TableCell>
                {trend.title.map((title, i) => (
                  <Accordion key={i}>
                    <AccordionSummary>
                      <Text variant="subtitle2">
                        <a href={trend.link[i]}>{title}</a>
                        <Text className={classes.creators}>
                          {trend.creators[i]}
                        </Text>
                      </Text>
                      <ExpandMoreOutlined />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Text className={classes.abstract}>
                        {trend.abstract[i]}
                      </Text>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default Politics;
