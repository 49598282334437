import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { Component } from "react";
import SpeedDial from "../components/trends/views/speed_dial";

// render the HomeTwo component within the grid container so it doesnt overflow

class Mobile extends Component {
  render() {
    return (
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12}>
          <Container>
          <SpeedDial />
          </Container>
        </Grid>
        </Grid>
    );
  }
}

export default Mobile;
