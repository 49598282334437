import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
} from "@mui/material";

const FactCheckComponent = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const getFactCheck = async (event) => {
    // This prevents the page from reloading upon submission
    event.preventDefault();
    setIsLoading(true);

    try {
      const params = {
        query,
        languageCode: "en-US",
        key: process.env.REACT_APP_GOOGLE_FACT_CHECK_API_KEY,
      };
      const headers = {
        Accept: "application/json",
      };
      const response = await axios.get(
        "https://factchecktools.googleapis.com/v1alpha1/claims:search",
        { params, headers }
      );

      if (response.data.claims.length === 0) {
        setError("No results found for this query.");
      } else {
        setResults(response.data.claims);
        setError(null);
      }
    } catch (err) {
      setError("An error occurred while fetching the data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="h3" style={{ opacity: "0.6" }}>
        FACT CHECK
      </Typography>

      <form onSubmit={getFactCheck}>
        <TextField
          variant="outlined"
          label="Enter claim to fact check..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          sx={{ marginTop: 2, marginBottom: 2, width: "100%" }}
        />
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Fact Check"}
          </Button>
        </Box>{" "}
      </form>

      {error ? (
        <p>{error}</p>
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table >
            <TableHead
              style={{
                backgroundColor: "rgba(128, 128, 128, 0.5)",
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <TableRow>
                <TableCell style={{ borderRight: "1px solid lightgrey" }}>Claim</TableCell>
                <TableCell style={{ borderRight: "1px solid lightgrey" }}>Review</TableCell>
                <TableCell style={{ borderRight: "1px solid lightgrey" }}>Source</TableCell>
                <TableCell style={{ borderRight: "1px solid lightgrey" }}>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((result, index) => {
                // Convert claimDate to a Date object and format it
                const formattedDate = new Date(
                  result.claimDate
                ).toLocaleDateString("en-CA", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                });

                return (
                  <TableRow key={index}>
                    {/* Apply light mustard background color with 0.5 opacity to this cell */}
                    <TableCell
                      style={{
                        backgroundColor: "rgba(255, 219, 88, 0.5)",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      {result.text}
                    </TableCell>

                    {/* Other cells with vertical line */}
                    <TableCell style={{ borderRight: "1px solid lightgrey" }}>
                      {result.claimReview[0].textualRating}
                      {/* existing link code */}
                    </TableCell>

                    <TableCell style={{ borderRight: "1px solid lightgrey" }}>
                      {result.claimant}
                    </TableCell>

                    {/* No right border for the last cell */}
                    <TableCell>{formattedDate}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default FactCheckComponent;
