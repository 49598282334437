import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import Planet from '../components/trends/Planet';

import Profile from '../components/profile/Profile';


class gravity extends Component {
  render() {
    return (
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12}>
        <Planet/>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Profile />
        </Grid>
      </Grid>
    );
  }
}


export default gravity;