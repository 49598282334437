import {
  Button,
  MenuItem,
  Select,
  TableBody,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ListTwoTone } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { db } from "../../../../util/firebaseConfig";

const HomeRedditShut = (props) => {
  const [reddit, setReddit] = useState([]);

  useEffect(() => {
    db.collection("newshour")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setReddit(data);
      });
  }, []);

  const SentimentBar = ({ sentiment }) => {
    const posWidth = sentiment > 0 ? sentiment * 150 : 0;
    const negWidth = sentiment < 0 ? Math.abs(sentiment) * 150 : 0;
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          backgroundColor: "silver",
          borderRadius: "5px",
          width: "50px",
          height: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "red",
            borderRadius: "5px",
            width: `${negWidth}%`,
            height: "10px",
            justifySelf: 'end',
          }}
        ></div>
        <div
          style={{
            backgroundColor: "green",
            borderRadius: "5px",
            width: `${posWidth}%`,
            height: "10px",
            justifySelf: 'start',
          }}
        ></div>
      </div>
    );
  };

  const classes = props;
  return (
    <div>
      {reddit.map((loc, i) => (
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" style={{ opacity: 0.5 }}>
                  Headline (r/WorldNews)
                </Typography>
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <Typography variant="h6" style={{ opacity: 0.5 }}>
                  Comments
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loc.data.slice(0, 10).map((row, j) => (
              <TableRow key={j}>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h5"
                      style={{ opacity: 0.5, marginRight: "10px" }}
                    >
                      {row.index + 1}
                    </Typography>
                    <SentimentBar sentiment={row.sentiment} />
                  </div>
                  <a href={row.url}>{row.title}</a>
                </TableCell>
                <TableCell>
                  <Button>
                    <Select IconComponent={ListTwoTone} disableUnderline>
                      <MenuItem
                        style={{
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                          wordWrap: "break-word",
                          fontSize: "small",
                          flexWrap: "wrap",
                        }}
                      >
                        <TableCell>{row.comment1}</TableCell>
                        <TableCell>{row.comment2}</TableCell>
                        <TableCell>{row.comment3}</TableCell>
                        <TableCell>{row.comment4}</TableCell>
                        <TableCell>{row.comment5}</TableCell>
                      </MenuItem>
                    </Select>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  );
};

export default HomeRedditShut;
