import React from "react";
import { db } from "../../../../util/firebaseConfig";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Text from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  creators: {
    fontSize: "9px",
  },
  source: {
    fontSize: "8px",
  },
  abstract: {
    fontSize: "small",
  },
  titleLink: {
    variant: "subtitle2",
  },
}));

const HomePubmed = () => {
  const [wikitrends, setWikitrends] = React.useState([]);
  const classes = useStyles();
  React.useEffect(() => {
    db.collection("pubmed")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setWikitrends(data);
      });
  }, []);

  return (
    <div>
      {wikitrends.map((loc, i) => (
        <Table aria-label="simple table">
          <TableBody>
            {loc.data.slice(0, 10).map((row, j) => (
              <TableRow key={j} style={{ borderBottom: "1px solid #e0e0e0" }}>
                <Accordion disableGutters elevation={0} square>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      align="left"
                      gutterBottom
                    >
                      <a
                        href={
                          "https://pubmed.ncbi.nlm.nih.gov/" + `${row.pmid}`
                        }
                      >
                        {row.title}
                      </a>
                      <p className={classes.creators}>{row.author}</p>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body2"
                      display="block"
                      gutterBottom
                      align="left"
                      paragraph
                    >
                      <p></p>
                      <b>Abstract</b>
                      <p></p>
                      {row.abstract}
                      <p></p>
                      <Divider light />
                      <p></p>
                      <b>Simple Explanation</b>
                      <p></p>
                      <p>{row.simple}</p>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  );
};

export default HomePubmed;
