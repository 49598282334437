import Vimeo from "@u-wave/react-vimeo";
import React from "react";



class Currents extends React.Component {
  render() {
    return (
      <Vimeo 
      video="584463991"
      width="350%"
      loop='true'
      />
    );
  }
}

export default Currents;
