import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { db } from "../../util/firebaseConfig";
import "/Users/jaso/code/webdev/relic-client/src/App.css";


class YTMusic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weather: [],
    };
  }

  componentDidMount() {
    db.collection("chart_youtube")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ weather: data });
      });
  }
  render() {
    const classes = this.props;
    const { weather } = this.state;
    return (
      <Card className={classes.card} variant="outlined">
          {weather.map((loc, i) => (
           <CardContent className={classes.content} style={{overflow:"auto"}}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">Video</TableCell>
                  <TableCell align="center">Views</TableCell>
                  <TableCell align="center">Likes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loc.data.map((row, j) => (
                <TableRow key={j}>
                  <TableCell align="center">{row.pos}</TableCell>
                  <TableCell align="center"><a href={row.url}>{row.video}</a></TableCell>
                  <TableCell align="center">{row.views}</TableCell>
                  <TableCell align="center">{row.likes}</TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
            </CardContent> 
          ))}
      </Card>
    );
  }
}

export default YTMusic;
