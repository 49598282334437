import { FormControl, InputLabel, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { db } from "../../../../util/firebaseConfig";


// the columns in the data are person, total, index and there are 10 datetime objects.. the datetime objects form the data for a line graph that will be displayed in each row of the table. these cells are named dt1, dt2, dt3, dt4, dt5, dt6, dt7, dt8, dt9, dt10

const GDeltOrgs = () => {
  const [gdp, setGdp] = useState([]);
  const [numRows, setNumRows] = useState(96);

  useEffect(() => {
    db.collection("gdelt_orgs")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setGdp(data);
      });
  }, []);

  const options = {
    hAxis: {
      textPosition: "none",
      gridlines: {
        color: "transparent",
      },
    },
    vAxis: {
      maxValue: 500,
      textPosition: "none",
      gridlines: {
        color: "transparent",
      },
    },
    legend: {
      position: "none",
    },
  };

  return (
    <div>
      <Table>
        <TableRow>
          <TableCell>
            <Typography variant="h3" style={{ opacity: 0.6 }} gutterBottom>
              GDELT
            </Typography>
            <Typography variant="h6" style={{ opacity: 0.6 }} gutterBottom>
              GKG - Global Knowledge Graph Organisations
            </Typography>
            <br></br>
            <div className="select-container">
              <Typography variant="caption" style={{ opacity: 0.6 }}> Select number of hours to display: &nbsp;</Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Hours</InputLabel>
              <Select
                value={numRows}
                onChange={(e) => setNumRows(e.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={numRows}

              >

                <MenuItem value={4}> 1 hr</MenuItem>
                <MenuItem value={8}> 2 hrs</MenuItem>
                <MenuItem value={12}> 3 hrs</MenuItem>
                <MenuItem value={24}> 6 hrs</MenuItem>
                <MenuItem value={48}> 12 hrs</MenuItem>
                <MenuItem value={96}> 24 hrs</MenuItem>
              </Select>
              </FormControl>
              </div>
          </TableCell>
        </TableRow>
      </Table>

      {gdp.map((loc, i) => (
        <Table aria-label="simple table">
          <TableBody>
            {loc.data.slice(0, 10).map((row, j) => (
              <TableRow key={j}>
                <TableCell align="center">
                  <Typography
                    variant="h3"
                    style={{ opacity: 0.3 }}
                    gutterBottom
                  >
                    {row.index + 1}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <a href={"https://news.google.com/search?q=" + `${row.org}`}>
                    <Typography
                      variant="h7"
                      style={{ opacity: 0.8 }}
                      gutterBottom
                    >
                      {row.org}
                    </Typography>
                  </a>
                </TableCell>
                <TableCell>
                <Chart
                      chartType="LineChart"
                      width="200px"
                      height="100px"
                      data={[
                        ["index", "Total"],
                        ...Array.from({ length: numRows }, (_, i) => [
                          i,
                          row[`dt${100 - (numRows - i)}`],
                        ]).slice(0, numRows),
                      ]}
                      options={options}
                    />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  );
};

export default GDeltOrgs;
