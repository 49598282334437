import { Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { db } from "../../util/firebaseConfig";
import '/Users/jaso/code/webdev/relic-client/src/App.css';

const styles = {
  container: {
    maxHeight: 400,
    overflow: 'auto',
  },
  root: {
    width: "100%",
    overflow: 'auto',
  },
};

class Weather extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weather: [],
    };
  }

  componentDidMount() {
    db.collection("weather")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ weather: data });
      });
  }
  render() {
    const classes = this.props;
    const { weather } = this.state;
    return (
      <Paper className={styles.root}>
        <TableContainer style={{maxHeight:500}}>
          {weather.map((loc, i) => (
            <Table
              className="table-one"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                <TableCell></TableCell>
                  <TableCell>City</TableCell>
                  <TableCell align="center">Temp</TableCell>
                  <TableCell align="center">Min</TableCell>
                  <TableCell align="center">Max</TableCell>
                  <TableCell align="center">
                    Historical Monthly Average
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{loc.data[0].Country}</TableCell>
                  <TableCell>{loc.data[0].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[0].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[0].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[0].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[0].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[1].Country}</TableCell>
                  <TableCell>{loc.data[1].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[1].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[1].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[1].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[1].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[2].Country}</TableCell>
                  <TableCell>{loc.data[2].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[2].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[2].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[2].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[2].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[3].Country}</TableCell>
                  <TableCell>{loc.data[3].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[3].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[3].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[3].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[3].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[4].Country}</TableCell>
                  <TableCell>{loc.data[4].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[4].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[4].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[4].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[4].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[5].Country}</TableCell>
                  <TableCell>{loc.data[5].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[5].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[5].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[5].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[5].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[6].Country}</TableCell>
                  <TableCell>{loc.data[6].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[6].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[6].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[6].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[6].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[7].Country}</TableCell>
                  <TableCell>{loc.data[7].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[7].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[7].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[7].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[7].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[8].Country}</TableCell>
                  <TableCell>{loc.data[8].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[8].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[8].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[8].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[8].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[9].Country}</TableCell>
                  <TableCell>{loc.data[9].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[9].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[9].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[9].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[9].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[10].Country}</TableCell>
                  <TableCell>{loc.data[10].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[10].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[10].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[10].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[10].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[11].Country}</TableCell>
                  <TableCell>{loc.data[11].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[11].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[11].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[11].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[11].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[12].Country}</TableCell>
                  <TableCell>{loc.data[12].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[12].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[12].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[12].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[12].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[13].Country}</TableCell>
                  <TableCell>{loc.data[13].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[13].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[13].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[13].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[13].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[14].Country}</TableCell>
                  <TableCell>{loc.data[14].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[14].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[14].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[14].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[14].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[15].Country}</TableCell>
                  <TableCell>{loc.data[15].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[15].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[15].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[15].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[15].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[16].Country}</TableCell>
                  <TableCell>{loc.data[16].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[16].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[16].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[16].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[16].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[17].Country}</TableCell>
                  <TableCell>{loc.data[17].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[17].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[17].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[17].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[17].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[18].Country}</TableCell>
                  <TableCell>{loc.data[18].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[18].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[18].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[18].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[18].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[19].Country}</TableCell>
                  <TableCell>{loc.data[19].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[19].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[19].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[19].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[19].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[20].Country}</TableCell>
                  <TableCell>{loc.data[20].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[20].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[20].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[20].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[20].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[21].Country}</TableCell>
                  <TableCell>{loc.data[21].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[21].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[21].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[21].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[21].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[22].Country}</TableCell>
                  <TableCell>{loc.data[22].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[22].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[22].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[22].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[22].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[23].Country}</TableCell>
                  <TableCell>{loc.data[23].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[23].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[23].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[23].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[23].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[24].Country}</TableCell>
                  <TableCell>{loc.data[24].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[24].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[24].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[24].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[24].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[25].Country}</TableCell>
                  <TableCell>{loc.data[25].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[25].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[25].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[25].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[25].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[26].Country}</TableCell>
                  <TableCell>{loc.data[26].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[26].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[26].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[26].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[26].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[27].Country}</TableCell>
                  <TableCell>{loc.data[27].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[27].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[27].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[27].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[27].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[28].Country}</TableCell>
                  <TableCell>{loc.data[28].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[28].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[28].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[28].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[28].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[29].Country}</TableCell>
                  <TableCell>{loc.data[29].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[29].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[29].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[29].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[29].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[30].Country}</TableCell>
                  <TableCell>{loc.data[30].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[30].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[30].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[30].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[30].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[31].Country}</TableCell>
                  <TableCell>{loc.data[31].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[31].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[31].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[31].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[31].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[32].Country}</TableCell>
                  <TableCell>{loc.data[32].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[32].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[32].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[32].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[32].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[33].Country}</TableCell>
                  <TableCell>{loc.data[33].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[33].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[33].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[33].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[33].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[34].Country}</TableCell>
                  <TableCell>{loc.data[34].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[34].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[34].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[34].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[34].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[35].Country}</TableCell>
                  <TableCell>{loc.data[35].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[35].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[35].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[35].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[35].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[36].Country}</TableCell>
                  <TableCell>{loc.data[36].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[36].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[36].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[36].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[36].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[37].Country}</TableCell>
                  <TableCell>{loc.data[37].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[37].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[37].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[37].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[37].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[38].Country}</TableCell>
                  <TableCell>{loc.data[38].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[38].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[38].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[38].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[38].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[39].Country}</TableCell>
                  <TableCell>{loc.data[39].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[39].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[39].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[39].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[39].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[40].Country}</TableCell>
                  <TableCell>{loc.data[40].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[40].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[40].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[40].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[40].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[41].Country}</TableCell>
                  <TableCell>{loc.data[41].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[41].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[41].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[41].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[41].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[42].Country}</TableCell>
                  <TableCell>{loc.data[42].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[42].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[42].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[42].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[42].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[43].Country}</TableCell>
                  <TableCell>{loc.data[43].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[43].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[43].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[43].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[43].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[44].Country}</TableCell>
                  <TableCell>{loc.data[44].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[44].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[44].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[44].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[44].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[45].Country}</TableCell>
                  <TableCell>{loc.data[45].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[45].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[45].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[45].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[45].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[46].Country}</TableCell>
                  <TableCell>{loc.data[46].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[46].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[46].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[46].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[46].Monthly_Average}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{loc.data[47].Country}</TableCell>
                  <TableCell>{loc.data[47].City}</TableCell>
                  <TableCell align="center">
                    {loc.data[47].Current_Temp}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[47].Daily_Temp_Min}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[47].Daily_Temp_Max}
                  </TableCell>
                  <TableCell align="center">
                    {loc.data[47].Monthly_Average}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ))}
        </TableContainer>
      </Paper>
    );
  }
}

export default Weather;
