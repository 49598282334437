import React, { Component } from "react";
import { Link } from "react-router-dom";
import MenuListComposition from "./Menu";
// import the logo from public/mang.gif
import logo from "../../images/icon.png";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HomeIcon from "@material-ui/icons/Home";
import InvertColors from "../../util/InvertColours";
import SearchIcon from "@mui/icons-material/Search";

class Navbar extends Component {
  render() {
    return (
      <AppBar>
        <Toolbar>
          <img
            src={logo}
            edge="start"
            height="55"
            style={{ marginRight: "10px" }}
            alt="logo"
          />
          <div
            style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
          >
            <Button tip="Home" component={Link} to="/">
              <HomeIcon />
            </Button>
            <Button tip="Search" component={Link} to="/search">
              <SearchIcon />
            </Button>
            {/* <Button tip="Fact Check" component={Link} to="/facts">
              <FactCheckIcon />
            </Button> */}
            <MenuListComposition />
            <InvertColors />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Navbar;
