import { Divider, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { db } from "../../../../util/firebaseConfig";

const styles = {
  header: {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  subheader: {
    fontSize: "0.3rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  card: {
    marginBottom: 20,
  },
};
class HomePop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reddit: [],
    };
  }

  componentDidMount() {
    db.collection("reddit")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        this.setState({ reddit: data });
      });
  }
  render() {
    const classes = this.props;
    const { reddit } = this.state;
    return (
      <Card className={classes.card} variant="outlined">
        {reddit.map((loc, i) => (
          <CardContent className={classes.content}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody className={classes.content}>
                {loc.data.slice(0,10).map((row, j) => (
                  <TableRow key={j}>
                    <TableCell
                      style={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        wordWrap: "break-word",
                        fontSize: "small",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        variant="h5"
                        paragraph
                        style={{ fontWeight: "bold", opacity: 0.5 }}
                      >
                        {row.index + 1}
                      </Typography>
                      <Typography variant="h6" style={{ opacity: 0.9 }}>
                      <a href={row.url}>{row.title}</a>
                      </Typography>
                      <Divider />
                      <p><b>Top Comments: </b></p>
                      <p>1. {row.comment1}</p>
                      <p>2. {row.comment2}</p>
                      <p>3. {row.comment3}</p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        ))}
      </Card>
    );
  }
}

export default HomePop;
