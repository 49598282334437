import {
  Button,
  Card,
  CardContent,
  MenuItem,
  Select
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import React from "react";

// PAGES
import Apprankings from "../../pages/apprankings";
import Aqi from "./Aqi";
import FilmAggregator from "./FilmAgg";
import GDeltO from "./GDELT_Org";
import GDeltP from "./GDELT_People";
import News from "./Google";
import GHub from "./g_language";
import MusicAggregator from "./MusicAgg";
import RedditShut from "./Ohno";
import PaperAgg from "./PaperAgg";
import RedditTrends from "./RedditTrends";
import HackNews from "./views/homeco/h_hackernews";
import Wikitrends from "./WikiTrends";
import AiNews from "./AiNews";
// import Slide from "./Slide";
// ICONS
import { MusicNote, Twitter } from "@material-ui/icons";
import {
  BookOnline, GitHub,
  Google, Masks, MobileFriendly, Reddit
} from "@mui/icons-material";
import PublicIcon from "@mui/icons-material/Public";
import { FaWikipediaW } from "react-icons/fa";
import { GrOrganization } from "react-icons/gr";
import { MdLocalMovies as Movie } from "react-icons/md";
import { VscPerson } from "react-icons/vsc";
import { AutoStories } from "@mui/icons-material";
import { FaRobot } from "react-icons/fa";
import { FaYCombinator } from "react-icons/fa";

const styles = {
  tweet: {
    fontSize: "0.8rem",
  },
  tabTitle: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  card: {
    marginBottom: 20,
  },
  titleCard: {
    marginBottom: 20,
    backgroundColor: "rgb(20,40,100,0.2)",
  },
  wrapper: {
    textAlign: "center",
  },
  button: {
    marginTop: 15,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 10,
    color: "green",
  },
  text: {
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 12,
  },
  videoCard: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    spacing: 0,
    direction: "column",
  },
};

export default function MenuDD() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <AppBar position="static" color="default" paddingTop="100">
        <Select value={value} onChange={handleChange} defaultValue={value}>
          <MenuItem value={0}>
            <Button>
              <Google />
            </Button>
            GOOGLE
          </MenuItem>
          <MenuItem value={1}>
            <Button>
              <FaYCombinator />
            </Button>
            HACKERNEWS
          </MenuItem>
          <MenuItem value={2}>
            <Button>
              <PublicIcon />
            </Button>
            WORLD NEWS
          </MenuItem>
          <MenuItem value={3}>
            <Button>
              <FaWikipediaW size={23} />
            </Button>
            WIKIPEDIA
          </MenuItem>
          <MenuItem value={4}>
            <Button>
              <MusicNote />
            </Button>
            MUSIC
          </MenuItem>
          <MenuItem value={5}>
            <Button>
              <Movie size={23} />
            </Button>
            SCREEN
          </MenuItem>
          <MenuItem value={6}>
            <Button>
              <GitHub />
            </Button>
            GITHUB
          </MenuItem>
          <MenuItem value={7}>
            <Button>
              <AutoStories />
            </Button>
            RESEARCH
          </MenuItem>
          <MenuItem value={8}>
            <Button>
              <Reddit />
            </Button>
            REDDIT
          </MenuItem>
          <MenuItem value={9}>
            <Button>
              <GrOrganization size={23} />
            </Button>
            GDELT ORG
          </MenuItem>
          <MenuItem value={10}>
            <Button>
              <VscPerson size={23} />
            </Button>
            GDELT ENTITIES
          </MenuItem>
          <MenuItem value={11}>
            <Button>
              <FaRobot />
            </Button>
            AI NEWS
          </MenuItem>
          <MenuItem value={12}>
            <Button>
              <Masks />
            </Button>
            AIR QUALITY INDEX
          </MenuItem>
          <MenuItem value={13}>
            <Button>
              <MobileFriendly />
            </Button>
            APPS
          </MenuItem>
        </Select>
      </AppBar>
      <Card>
        <CardContent>
          {value === 0 && <News />}
          {value === 1 && <HackNews />}
          {value === 2 && <RedditShut />}
          {value === 3 && <Wikitrends />}
          {value === 4 && <MusicAggregator />}
          {value === 5 && <FilmAggregator />}
          {value === 6 && <GHub />}
          {value === 7 && <PaperAgg />}
          {value === 8 && <RedditTrends />}
          {value === 9 && <GDeltO />}
          {value === 10 && <GDeltP />}
          {value === 11 && <AiNews />}
          {value === 12 && <Aqi />}
          {value === 13 && <Apprankings />}
        </CardContent>
      </Card>
    </div>
  );
}
