import React, { useState, useEffect } from "react";
import { db } from "../../../../util/firebaseConfig";
import "/Users/jaso/code/webdev/relic-client/src/App.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Text from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { TextField } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@mui/material";
import { Chip, IconButton } from "@material-ui/core";
import labels from "./label_dict.json";
import Autocomplete from "@mui/material/Autocomplete";
import { SendAndArchive } from "@mui/icons-material";

const HomeNews = (props) => {
  const [nugits, setNugits] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleCheckboxChange = (row) => {
    const rowWithUpdatedCategory = {
      ...row,
      category: selectedCategory || row.category,
    };

    db.collection("rlhf")
      .add(rowWithUpdatedCategory)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        setSelectedCategory(null); // Clear the selected category after db update
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const SentimentBar = ({ sentiment }) => {
    const posWidth = sentiment > 0 ? sentiment * 150 : 0;
    const negWidth = sentiment < 0 ? Math.abs(sentiment) * 150 : 0;
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          backgroundColor: "silver",
          borderRadius: "5px",
          width: "30px",
          height: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "red",
            borderRadius: "5px",
            width: `${negWidth}%`,
            height: "10px",
            justifySelf: "end",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "green",
            borderRadius: "5px",
            width: `${posWidth}%`,
            height: "10px",
            justifySelf: "start",
          }}
        ></div>
      </div>
    );
  };

  useEffect(() => {
    db.collection("new_goo")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setNugits(data);
      });
  }, []);

  const classes = props;
  return (
    <div>
      {nugits.map((loc, i) => (
        <Table
          className={classes.table}
          aria-label="simple table"
          style={{ borderColor: "white", borderBottom: "none" }}
        >
          <TableBody style={{ borderColor: "white", borderBottom: "none" }}>
            {loc.data.slice(0, 10).map((row, j) => (
              <Accordion
                disableGutters
                elevation={0}
                square
                expanded={expanded === `panel${j}`}
                onChange={handleChange(`panel${j}`)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div style={{ marginTop: "20px" }}>
                    <SentimentBar sentiment={row.sentiment} />
                  </div>
                  <TableCell
                    style={{ borderColor: "white", borderBottom: "none" }}
                  >
                    <Typography
                      variant="h7"
                      style={{ fontWeight: "bold", opacity: 0.9 }}
                    >
                      <a href={row.url}>{row.topic}</a>
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      flex: 1,
                      borderColor: "white",
                      borderBottom: "none",
                    }}
                  >
                    <Typography variant="body2" align="right">
                      <div>
                        <Chip
                          label={row.category}
                          size="small"
                          style={{
                            height: "20px",
                            fontSize: "10px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        />
                      </div>
                    </Typography>
                  </TableCell>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Table
                      style={{ borderColor: "white", borderBottom: "none" }}
                    >
                      <TableRow key={j}>
                        <TableCell>
                          <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                            # of Countries
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text style={{ fontSize: 12 }}>
                            {row.country_count}
                          </Text>
                        </TableCell>
                      </TableRow>
                      <TableRow key={j}>
                        <TableCell>
                          <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                            Summary
                          </Text>
                        </TableCell>
                        <TableCell>
                          {/* <MuiMarkdown>{row.summary}</MuiMarkdown> */}
                          <Text style={{ fontSize: 12 }}>{row.summary}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                            Score
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text style={{ fontSize: 12 }}>{row.score}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                            Countries
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text style={{ fontSize: 12 }}>{row.country}</Text>
                        </TableCell>
                      </TableRow>
                    </Table>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "20px",
                        width: "100%",
                      }}
                    >
                      <div style={{ flex: 1, marginRight: "20px" }}>
                        <Autocomplete
                          options={Object.keys(labels)}
                          value={selectedCategory}
                          onChange={(event, newValue) => {
                            setSelectedCategory(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              sx={{
                                backgroundColor: "grey",
                                margin: "0 auto", // centering
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "5px", // Rounded corners
                                  borderColor: "silver", // Add silver outline
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none", // Remove border
                                },
                                "& .MuiInputBase-input": {
                                  borderBottom: "none", // Remove underline
                                },
                              }}
                            />
                          )}
                          fullWidth
                        />
                      </div>
                      <IconButton
                        onClick={() => handleCheckboxChange(row)}
                        style={{
                          border: "1px solid silver",
                          borderRadius: "5px",
                        }}
                      >
                        <SendAndArchive
                          style={{ color: "blue", opacity: 0.8 }}
                        />
                      </IconButton>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  );
};

export default HomeNews;
