import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Link } from "react-router-dom";
//icons
import PublicIcon from "@material-ui/icons/Public";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CloudIcon from "@material-ui/icons/Cloud";
import {
  ChatBubbleTwoTone,
  EqualizerOutlined,
  LocalActivity,
  PostAdd,
} from "@material-ui/icons";
import { SiOpenai } from "react-icons/si";
import SearchIcon from "@mui/icons-material/Search";
import { Menu } from "@material-ui/core";
import { FactCheckOutlined } from "@mui/icons-material";

export default function MenuListComposition() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MenuBookIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {/* <MenuItem onClick={handleClose} component={Link} to="/trend"><EqualizerIcon/></MenuItem>   */}
                  <MenuItem onClick={handleClose} component={Link} to="/trend">
                    <EqualizerOutlined /> &nbsp; Trends
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/gravity"
                  >
                    <PublicIcon /> &nbsp; Gravity
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/weather"
                  >
                    <CloudIcon /> &nbsp; Weather
                  </MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to="/wall">
                    <PostAdd /> &nbsp; Mural
                  </MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to="/facts">
                    <FactCheckOutlined /> &nbsp; Fact Check
                  </MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to="/tests">
                    <LocalActivity /> &nbsp; National Trends
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose} component={Link} to="/search">
                    <SearchIcon /> &nbsp; Search
                  </MenuItem> */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
