import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
// Components
import Navbar from "./components/layout/Navbar";
import themeObject from "./util/theme";
import ScrollToTop from "./components/layout/ScrollTop";
import { getCookie, setCookie } from "./util/CookieHelper";

// Pages
import arry from "./pages/arry";
import gravity from "./pages/gravity";
import Mobile from "./pages/mobile";
import olympics from "./pages/olympics";
import testSuite from "./pages/testSuite";
import trendaggregator from "./pages/trendaggregator";
import weather from "./pages/weather";
import FactCheckComponent from "./components/chats/factCheck";
import Search from "./pages/search";
import Dev from "./pages/dev";

import axios from "axios";
import WallContainer from "./components/wall/wall_container";

const theme = createTheme(themeObject);

axios.defaults.baseURL =
  "https://australia-southeast1-relic-d5367.cloudfunctions.net/api";

class App extends Component {
  state = {
    invertColors: false,
  };

  toggleInvertColors = () => {
    const { invertColors } = this.state;
    this.setState({ invertColors: !invertColors });
    const css = invertColors
      ? "html {-webkit-filter: invert(0%); -moz-filter: invert(0%); -o-filter: invert(0%); -ms-filter: invert(0%); }"
      : "html {-webkit-filter: invert(100%); -moz-filter: invert(100%); -o-filter: invert(100%); -ms-filter: invert(100%); }";
    const head = document.getElementsByTagName("head")[0];
    const style = document.createElement("style");
    style.type = "text/css";
    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
  };
  componentDidMount() {
    const invertColors = getCookie("invertColors");
    if (invertColors) {
      this.setState({ invertColors: invertColors === "true" });
      this.toggleInvertColors();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.invertColors !== this.state.invertColors) {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      setCookie("invertColors", this.state.invertColors, expirationDate);
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Navbar />

            <div className="container">
              <Switch>
                <Route exact path="/" component={Mobile} />
                <Route exact path="/gravity" component={gravity} />
                <Route exact path="/search" component={Search} />
                <Route exact path="/m" component={Mobile} />
                <Route exact path="/olympics" component={olympics} />
                <Route exact path="/arry" component={arry} />
                <Route exact path="/weather" component={weather} />
                <Route exact path="/trend" component={trendaggregator} />
                <Route exact path="/wall" component={WallContainer} />
                <Route exact path="/tests" component={testSuite} />
                <Route exact path="/facts" component={FactCheckComponent} />
                <Route exact path="/dev" component={Dev} />
                {/* <Route exact path="/qa" component={QuestionComponent} /> */}
                {/* <Route exact path="/chat" component={PromptBot} /> */}
              </Switch>
              <ScrollToTop />
            </div>
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
