import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

// PAGES
import ArtChart from "./ArtistCharts";
import Spotify from "./Spotify";
import YTMusic from "./YoutubeMusic";
// import Slide from "./Slide";
// ICONS

const styles = {
  tweet: {
    fontSize: "0.8rem",
  },
  tabTitle: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  tabContent: {
    overflowX:"scroll",
    overflowY:"scroll",
  },
  card: {
    marginBottom: 20,
  },
  titleCard: {
    marginBottom: 20,
    backgroundColor: "rgb(20,40,100,0.2)",
  },
  wrapper: {
    textAlign: "center",
  },
  button: {
    marginTop: 15,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 10,
    color: "green",
  },
  text: {
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 12,
  },
  videoCard: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    spacing: 0,
    direction: "column",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function MusicAggregator() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Artist Charts" {...a11yProps(0)} />
          <Tab label="Spotify" {...a11yProps(1)} />
          <Tab label="Youtube Music" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ArtChart />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Spotify />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <YTMusic />
      </TabPanel>
    </div>
  );
}
