import { Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { db } from "../../util/firebaseConfig";


const styles = {
  header: {
      fontSize: "1rem",
      fontWeight: "bold",
      textAlign: "center",
  },
  subheader: {
      fontSize: "0.3rem",
      fontWeight: "bold",
      textAlign: "center",
  },
  card: {
      marginBottom: 20,
  },
}
class AiNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wikitrends: [],
    };
  }

  componentDidMount() {
    db.collection("headlines")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        this.setState({ wikitrends: data });
      });
  }
  render() {

    const classes = this.props;
    const { wikitrends } = this.state;
    return (
      <Card className={classes.card} variant="outlined">
        {wikitrends.map((loc, i) => (
          <CardContent className={classes.content}>
            <Typography variant="h4" style={{opacity:0.6}}>Ai News Weekly</Typography>
            <Typography variant="h7" style={{opacity:0.6}}>Truth Not Guaranteed.</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Trend Points</TableCell>
                  <TableCell>Article</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loc.data.map((row, j) => (
                <TableRow key={j}>
                  <TableCell align="center">{row.count}</TableCell>
                  <TableCell style={{whiteSpace: "normal", wordBreak:"break-word", wordWrap: "break-word",fontSize:"small",flexWrap:"wrap"}}><a href= {("https://en.wikipedia.org/wiki/")+`${row.topic}`}>{row.topic}</a>
                  <p>{row.summary}</p>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        ))}
      </Card>
    );
  }
}

export default AiNews;
