import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

// PAGES
import Psych from "./0_Psych_trend";
import Politics from "./1_Politics_trend";
import History from "./2_History_trend";
import DataProc from "./3_DataProc_trend";
import ExtraPapers from "./4_Other_Papers";
import Engineer from "./Engineering";
import Pubmed from "./Pubmed_updates";
// ICONS

const styles = {
  tweet: {
    fontSize: "0.8rem",
  },
  tabTitle: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  tabContent: {
    overflowX:"scroll",
    overflowY:"scroll",
  },
  card: {
    marginBottom: 20,
  },
  titleCard: {
    marginBottom: 20,
    backgroundColor: "rgb(20,40,100,0.2)",
  },
  wrapper: {
    textAlign: "center",
  },
  button: {
    marginTop: 15,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 10,
    color: "green",
  },
  text: {
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 12,
  },
  videoCard: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    spacing: 0,
    direction: "column",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function PaperAgg() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Engineering" {...a11yProps(0)} />
          <Tab label="Pubmed" {...a11yProps(1)} />
          <Tab label="History" {...a11yProps(2)} />
          <Tab label="Psychology" {...a11yProps(3)} />
          <Tab label="Politics" {...a11yProps(4)} />
          <Tab label="Data Processing" {...a11yProps(5)} />
          <Tab label="Other" {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Engineer />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Pubmed />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <History />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Psych />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Politics />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DataProc />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ExtraPapers />
      </TabPanel>
    </div>
  );
}
