import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import Tally from '../components/trends/Tally';

import Profile from '../components/profile/Profile';


class olympics extends Component {
  render() {
    return (
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12}>
        <Tally/>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Profile />
        </Grid>
      </Grid>
    );
  }
}


export default olympics;