import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { default as Text, default as Typography } from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { db } from "../../util/firebaseConfig";

const useStyles = makeStyles((theme) => ({
  creators: {
    fontSize: "9px",
  },
  source: {
    fontSize: "8px",
  },
  abstract: {
    fontSize: "small",
  },
  titleLink: {
    variant: "subtitle2",
  },
}));

const Pubmed = () => {
  const [wikitrends, setWikitrends] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    db.collection("pubmed")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setWikitrends(data);
      });
  }, []);

  return (
    <Card variant="outlined">
      <CardContent>

      {wikitrends.map((loc, i) => (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                Papers
              <a className={classes.source} href="https://pubmed.ncbi.nlm.nih.gov/trending/"> (Source) </a>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loc.data.map((row, j) => (
              <TableRow key={j}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      align="left"
                      gutterBottom
                    >
                      <a
                        href={
                          "https://pubmed.ncbi.nlm.nih.gov/" + `${row.pmid}`
                        }
                      >
                        {row.title}
                      </a>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                      align="left"
                    >
                      <p>{row.abstract}</p>
                      <p className={classes.creators}> Authors</p>
                      <p className={classes.creators}>{row.author}</p>
                      
                      </Text>
                  </AccordionDetails>
                </Accordion>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ))}
      </CardContent>
    </Card>
  );
};
export default Pubmed;
