import Grid from "@material-ui/core/Grid";
import React, { Component } from "react";
import AppStoreFree from "../components/trends/AppStoreFree";
import AppStorePaid from "../components/trends/AppStorePaid";
import PlayStoreFree from "../components/trends/PlayStoreFree";
import PlayStorePaid from "../components/trends/PlayStorePaid";

class Apprankings extends Component {
  render() {
    return (
      <Grid>
        <AppStoreFree />
        <p></p>
        <AppStorePaid />
        <p></p>
        <PlayStoreFree />
        <p></p>
        <PlayStorePaid />
      </Grid>
    );
  }
}

export default Apprankings;
