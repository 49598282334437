import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { db } from '../../util/firebaseConfig';

class HP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          HP: []
        };
      }

componentDidMount() {
    db.collection("HPFEED")
    .get()
    .then(querySnapshot =>{
        const data = querySnapshot.docs.map(doc => doc.data());
        console.log(data);
        this.setState({ HP: data })
    });
}
render() {
    const classes = this.props;
    const { HP } = this.state
    return (
        <Card className={classes.card}>
            {HP.map((A) => (
                <CardContent className = {classes.content}>
                    <h2 className={classes.content} align="center">Arry</h2>
                    <p></p>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date Published</TableCell>
                                <TableCell align="center">Headline</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">{A.Date0}</TableCell>
                                <TableCell><a href= {A.URL0}>{A.Title0}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date1}</TableCell>
                                <TableCell><a href= {A.URL1}>{A.Title1}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date2}</TableCell>
                                <TableCell><a href= {A.URL2}>{A.Title2}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date3}</TableCell>
                                <TableCell><a href= {A.URL3}>{A.Title3}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date4}</TableCell>
                                <TableCell><a href= {A.URL4}>{A.Title4}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date5}</TableCell>
                                <TableCell><a href= {A.URL5}>{A.Title5}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date6}</TableCell>
                                <TableCell><a href= {A.URL6}>{A.Title6}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date7}</TableCell>
                                <TableCell><a href= {A.URL7}>{A.Title7}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date8}</TableCell>
                                <TableCell><a href= {A.URL8}>{A.Title8}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date9}</TableCell>
                                <TableCell><a href= {A.URL9}>{A.Title9}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date10}</TableCell>
                                <TableCell><a href= {A.URL10}>{A.Title10}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date11}</TableCell>
                                <TableCell><a href= {A.URL11}>{A.Title11}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date12}</TableCell>
                                <TableCell><a href= {A.URL12}>{A.Title12}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date13}</TableCell>
                                <TableCell><a href= {A.URL13}>{A.Title13}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date14}</TableCell>
                                <TableCell><a href= {A.URL14}>{A.Title14}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date15}</TableCell>
                                <TableCell><a href= {A.URL15}>{A.Title15}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date16}</TableCell>
                                <TableCell><a href= {A.URL16}>{A.Title16}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date17}</TableCell>
                                <TableCell><a href= {A.URL17}>{A.Title17}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date18}</TableCell>
                                <TableCell><a href= {A.URL18}>{A.Title18}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date19}</TableCell>
                                <TableCell><a href= {A.URL19}>{A.Title19}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date20}</TableCell>
                                <TableCell><a href= {A.URL20}>{A.Title20}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date21}</TableCell>
                                <TableCell><a href= {A.URL21}>{A.Title21}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date22}</TableCell>
                                <TableCell><a href= {A.URL22}>{A.Title22}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date23}</TableCell>
                                <TableCell><a href= {A.URL23}>{A.Title23}</a></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{A.Date24}</TableCell>
                                <TableCell><a href= {A.URL24}>{A.Title24}</a></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            ))}
        </Card>
    )
}
}

export default HP


