import { Button, MenuItem, Paper, Select, TableBody, TableContainer } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ListTwoTone } from "@material-ui/icons";
import React from "react";
import { db } from "../../util/firebaseConfig";

class RedditShut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reddit: [],
    };
  }

  componentDidMount() {
    db.collection("newshour")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        this.setState({ reddit: data });
      });
  }
  render() {
    const classes = this.props;
    const { reddit } = this.state;
    return (
      <Card className={classes.card}>
        {reddit.map((loc, i) => (
          <CardContent className={classes.content}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell> Headline (r/WorldNews) </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Comments
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loc.data.map((row, j) => (
                    <TableRow key={j}>
                      <TableCell>
                        <a href={row.url}>{row.title}</a>
                      </TableCell>
                      <TableCell>
                        <Button>
                          <Select IconComponent={ListTwoTone} disableUnderline>
                            <MenuItem
                              style={{
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                                wordWrap: "break-word",
                                fontSize: "small",
                                flexWrap: "wrap",
                              }}
                            >
                              <TableCell>{row.comment1}</TableCell>
                              <TableCell>{row.comment2}</TableCell>
                              <TableCell>{row.comment3}</TableCell>
                              <TableCell>{row.comment4}</TableCell>
                              <TableCell>{row.comment5}</TableCell>
                            </MenuItem>
                          </Select>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        ))}
      </Card>
    );
  }
}

export default RedditShut;
