import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

// ICONS
import { SiYcombinator } from "react-icons/si";
import {
  ArrowCircleRight,
  GitHub,
  Google,
  Reddit,
  Tv,
} from "@mui/icons-material";
import PublicIcon from "@mui/icons-material/Public";
import { BsSpotify } from "react-icons/bs";
import { FaWikipediaW } from "react-icons/fa";
import { MdEngineering, MdLocalMovies as Movie } from "react-icons/md";
import { SiPubmed } from "react-icons/si";
import { TfiYoutube } from "react-icons/tfi";
import { GrOrganization } from "react-icons/gr";
import { VscPerson } from "react-icons/vsc";
import { Code } from "@mui/icons-material";
// PAGES
import HomeEng from "./homeco/h_engineering";
import HomeGithub from "./homeco/h_github";
import HomeNews from "./homeco/h_google";
import HomeMov_Stream from "./homeco/h_movies";
import HomePubmed from "./homeco/h_pubmed";
import HomePop from "./homeco/h_reddit";
import HomeSpotify from "./homeco/h_spotify";
import HomeTV_Stream from "./homeco/h_tv";
import HackNews from "./homeco/h_hackernews";
import HomeWikitrends from "./homeco/h_wiki";
import HomeRedditShut from "./homeco/h_worldnews";
import HomeYTMusic from "./homeco/h_youtube";
import Arx from "./homeco/h_arx";
import GDeltOrgs from "./homeco/h_gdelt_orgs";
import GDeltPeople from "./homeco/h_gdelt_people";
import { Box } from "@material-ui/core";

const ArxComponent = Arx;
const ArxIcon = <Code />;
const ArxTitle = "Arxiv";

const components = [
  [HomeNews, <ArrowCircleRight />, "Scroll"],
  [HomeNews, <Google />, "Google"],
  [HackNews, <SiYcombinator />, "Hacker News"],
  [HomeRedditShut, <PublicIcon />, "World News"],
  [HomeGithub, <GitHub />, "Github"],
  [HomeWikitrends, <FaWikipediaW size={23} />, "Wikipedia"],
  [HomeMov_Stream, <Movie size={23} />, "Films"],
  [HomeTV_Stream, <Tv />, "TV Shows"],
  [HomeSpotify, <BsSpotify size={23} />, "Spotify"],
  [HomeYTMusic, <TfiYoutube size={23} />, "Youtube Music"],
  [HomePubmed, <SiPubmed size={23} />, "Pubmed"],
  [HomeEng, <MdEngineering size={23} />, "Engineering"],
  [GDeltOrgs, <GrOrganization size={23} />, "GDELT Orgs"],
  [GDeltPeople, <VscPerson size={23} />, "GDELT People+"],
  [HomePop, <Reddit />, "Reddit"],
];

const SpeedDial = () => {
  const [selected, setSelected] = React.useState(0);
  const [lastClicked, setLastClicked] = React.useState(null);
  const matches = useMediaQuery("(min-width:850px)");
  // Determine which component to render
  const isArxSelected = selected === "Arx"; // Use a unique identifier for the Arx selection
  const Component = isArxSelected ? ArxComponent : components[selected][0];

  const onChange = (index) => {
    // Check if the last clicked component is HomeEng and the current click is also on HomeEng
    if (
      lastClicked === components.findIndex(([Comp]) => Comp === HomeEng) &&
      index === lastClicked
    ) {
      // Set a unique identifier for the Arx component to be selected
      setSelected("Arx");
    } else {
      setSelected(index);
    }
    setLastClicked(index); // Update the last clicked index
  };

  return (
    // add scroll behavior to the bottom navigation
    <div>
      <CssBaseline />
      <Component />
      <div style={{ height: "120px" }}></div>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          overflow: "scroll",
          borderTop: "1px solid #999999",
          borderRadius: "0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <BottomNavigation
            value={selected}
            showLabels
            sx={{
              width: matches ? "100%" : "100%",
              paddingLeft: matches ? "0px" : "650px",
              maxWidth: "500px",
              borderRadius: "0px",
            }}
          >
            {components.map(([Component, Icon, title], index) => (
              <BottomNavigationAction
                key={title}
                label={title}
                icon={Icon}
                onClick={() => onChange(index)}
              />
            ))}
          </BottomNavigation>
        </Box>
      </Paper>
    </div>
  );
};

export default SpeedDial;
