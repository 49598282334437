import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { Component } from "react";
import GooCoun from "../components/trends/views/homeco/h_goocoun";

// render the HomeTwo component within the grid container so it doesnt overflow

class testSuite extends Component {
  render() {
    return (
      <div>
        <GooCoun />
      </div>
    );
  }
}

export default testSuite;
