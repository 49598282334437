import React, { useState, useEffect } from "react";
import { db } from "../../../../util/firebaseConfig";
import Text from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  creators: {
    fontSize: "x-small",
  },
  source: {
    fontSize: "8px",
  },
  abstract: {
    fontSize: "small",
  },
  titleLink: {
    variant: "subtitle2",
  },
}));

const HomeEng = () => {
  const [eng_trend, setEngTrend] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    db.collection("eng_trend")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setEngTrend(data);
      });
  }, []);

  return (
    <div>
      {eng_trend.map((trend, i) => (
        <div key={i}>
          {trend.title.slice(0, 10).map((title, i) => (
            <Accordion key={i} disableGutters elevation={0} square>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                <Text variant="subtitle2">
                  <a href={trend.link[i]}>{title}</a>
                  <Text className={classes.creators}>{trend.creators[i]}</Text>
                </Text>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.abstract} paragraph>
                  {trend.abstract[i]}
                  <p></p>
                  <Divider light />
                  <p></p>
                  <b>Simple Explanation</b>
                  <p></p>
                  {trend.simple[i]}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ))}
    </div>
  );
};

export default HomeEng;
