import React from "react";
import { useState, useEffect } from "react";
import { db } from "../../../../util/firebaseConfig";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@mui/material";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = {
  header: {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    maxWidth: "100%", // Ensure it does not overflow the screen width
    overflowX: "auto", // Add horizontal scrolling on smaller screens
  },
  subheader: {
    fontSize: "0.3rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  card: {
    marginBottom: 20,
  },
};

const HomeWikitrends = (props) => {
  const [wikitrends, setWikitrends] = useState([]);
  const [useDaily, setUseDaily] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [bgColor, setBgColor] = useState("wheat");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const collectionName = useDaily ? "wiki_daily" : "wikitrends";
    db.collection(collectionName)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setWikitrends(data);
      });
  }, [useDaily]);

  const toggleSelection = () => {
    setUseDaily(!useDaily);
    setBgColor(bgColor === "wheat" ? "grey" : "wheat"); // Toggle background color
  };

  const classes = props;
  return (
    <div
      // stickyHeader and other stuff rolls underneath
      className={classes.header}
      style={{ display: "flex", width: "100%" }}
    >
      <div
        style={{ backgroundColor: bgColor, cursor: "pointer" }}
        onClick={toggleSelection}
      >
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            opacity: 0,
            marginRight: "7px",
            paddingTop: "80px",
          }}
        >
          ..__.
        </Typography>
      </div>

      <div style={{ width: "100%" }}>
        <div>
          {wikitrends.map((loc, i) => (
            <Table
              className={classes.table}
              stickyHeader
              aria-label="sticky table"
              style={{ borderColor: "white", borderBottom: "none" }}
            >
              <TableHead style={{ borderColor: "white", borderBottom: "none" }}>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                        opacity: 0.5,
                        marginRight: "7px",
                        paddingTop: "80px",
                      }}
                    >
                      {useDaily ? "daily" : "weekly"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ borderColor: "white", borderBottom: "none" }}>
                {loc.data.slice(0, 20).map((row, j) => (
                  <Accordion
                    disableGutters
                    elevation={0}
                    square
                    expanded={expanded === `panel${j}`}
                    onChange={handleChange(`panel${j}`)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <TableCell
                        style={{ borderColor: "white", borderBottom: "none" }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontWeight: "bold", opacity: 0.9 }}
                        >
                          {row.Rank}{" "}
                          <a
                            href={
                              "https://en.wikipedia.org/wiki/" +
                              row.Article.replace(/\s/g, "_")
                            }
                            style={{
                              opacity: 0.7,
                              textDecoration: "none",
                              // dont change color after visited
                              color: "inherit",
                            }}
                          >
                            {row.Article}
                          </a>
                        </Typography>
                        <Typography style={{ fontSize: 12 }} variant="caption">
                          Views: {row.Views}
                        </Typography>
                        <Typography style={{ fontSize: 12 }}>
                          {expanded === `panel${j}`
                            ? row.Notes
                            : `${row.Notes.substring(0, 50)}...`}
                        </Typography>
                      </TableCell>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                ))}
              </TableBody>
            </Table>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeWikitrends;
