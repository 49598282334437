import React from "react";
import { db } from "../../../../util/firebaseConfig";
import "/Users/jaso/code/webdev/relic-client/src/App.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = {
  container: {
    maxHeight: 400,
    overflow: "auto",
  },
  root: {
    width: "100%",
    overflow: "auto",
  },
};

class HomeYTMusic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weather: [],
    };
  }

  componentDidMount() {
    db.collection("chart_youtube")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ weather: data });
      });
  }
  render() {
    const classes = this.props;
    const { weather } = this.state;
    return (
      <div>
        {weather.map((loc, i) => (
            <Table className={classes.table} aria-label="simple table">
            
              <TableHead>
                <TableRow>
                  <TableCell align="center">Video</TableCell>
                  <TableCell align="center">Views</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loc.data.slice(0,10).map((row, j) => (
                <TableRow key={j}>
                  <TableCell align="center"><a href={row.url}>{row.video}</a></TableCell>
                  <TableCell align="center">{row.views}</TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
        ))}
      </div>
    );
  }
}



export default HomeYTMusic;
