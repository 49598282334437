import React, { useState, useEffect } from "react";
import { db } from "../../../../util/firebaseConfig";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Text from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@mui/material";
import { TableHead } from "@material-ui/core";
import Arx from "./h_arx";

const styles = {
  container: {
    maxHeight: 400,
    overflowX: "scroll",
  },
  root: {
    width: "100%",
    overflow: "auto",
  },
  credits: {
    fontSize: 2,
  },
};

const HackNews = (props) => {
  const [hackerNews, setHackerNews] = useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    db.collection("hacknews")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setHackerNews(data);
      });
  }, []);
  const SentimentBar = ({ sentiment }) => {
    const posWidth = sentiment > 0 ? sentiment * 150 : 0;
    const negWidth = sentiment < 0 ? Math.abs(sentiment) * 150 : 0;
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          backgroundColor: "silver",
          borderRadius: "5px",
          width: "50px",
          height: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "red",
            borderRadius: "5px",
            width: `${negWidth}%`,
            height: "10px",
            justifySelf: "end",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "green",
            borderRadius: "5px",
            width: `${posWidth}%`,
            height: "10px",
            justifySelf: "start",
          }}
        ></div>
      </div>
    );
  };

  const classes = props;
  return (
    <div>
      {hackerNews.map((loc, i) => (
        <Table
          className={classes.table}
          aria-label="simple table"
          style={{ borderColor: "white", borderBottom: "none" }}
        >
          <TableBody style={{ borderColor: "white", borderBottom: "none" }}>
            {loc.data.slice(0, 20).map((row, j) => (
              <Accordion
                disableGutters
                elevation={0}
                square
                expanded={expanded === `panel${j}`}
                onChange={handleChange(`panel${j}`)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <TableCell
                    style={{ borderColor: "white", borderBottom: "none" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h4"
                        style={{
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginRight: "10px",
                        }}
                      >
                        {row.index + 1}{" "}
                      </Typography>
                      <SentimentBar sentiment={row.Sentiment} />
                    </div>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", opacity: 0.9 }}
                    >
                      {row.Title}
                    </Typography>
                  </TableCell>
                </AccordionSummary>
                <AccordionDetails>
                  <Table style={{ borderColor: "white", borderBottom: "none" }}>
                    <TableRow>
                      <TableCell>
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          Comment Summary
                        </Text>
                      </TableCell>

                      <TableCell>
                        <Text style={{ fontSize: 12 }}>{row.Summary}</Text>
                      </TableCell>
                    </TableRow>
                    <TableRow key={j}>
                      <TableCell>
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          URL
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text style={{ fontSize: 12 }}>
                          <a href={row.URL} target="_blank" rel="noreferrer">
                            {row.URL.split("/")[2]}
                          </a>
                        </Text>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          Comments
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text style={{ fontSize: 12 }}>
                          <a
                            href={row.Comments_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row.Comments_URL}
                          </a>
                        </Text>
                      </TableCell>
                    </TableRow>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  );
};

export default HackNews;
