import React, { useState, useEffect } from "react";
import { db } from "../../../../util/firebaseConfig";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Container, Paper, Typography } from "@material-ui/core";

const GooCoun = (props) => {
  const [nugits, setNugits] = useState([]);
  const [country, setCountry] = useState("");

  const countries = [
    "united_states",
    "ireland",
    "canada",
    "italy",
    "australia",
    "mexico",
    "new zealand",
    "germany",
    "france",
    "united_kingdom",
    "south_africa",
    "indonesia",
  ];

  useEffect(() => {
    db.collection("goo_country")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log(data);
        setNugits(data);
      });
  }, []);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="country-select-label">Country</InputLabel>
        <Select
          labelId="country-select-label"
          id="country-select"
          value={country}
          onChange={handleCountryChange}
          label="Country"
        >
          {countries.map((country) => (
            <MenuItem key={country} value={country} defaultValue={'australia'}>
              <Typography variant="h4" style={{ fontWeight: "bold", opacity: 0.6 }}>
              {country.toUpperCase().replace("_", " ")}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Paper elevation={0} style={{ backgroundColor: "transparent" }}>
      {country && (
        <Table>
          <TableBody>
            {Object.entries(nugits[0][country]).slice(0,15).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell style={{ textAlign: "right" }}>
                  <Typography variant="h4" style={{ fontWeight: "bold", opacity: 0.6 }}>
                    {parseInt(key) + 1}
                  </Typography>
                  </TableCell>
                {/* the value should be a link to 'https://news.google.com/search?q=' + value + '+when:7d&hl=en-US&gl=US&ceid=US:en' */}
                <TableCell style={{ textAlign: "left", margin:"0.8 rem" }}>
                  <a
                    href={
                      "https://news.google.com/search?q=" +
                      value +
                      "+when:7d&hl=en-US&gl=US&ceid=US:en"
                    }
                  >
                    {value}
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      </Paper>
    </div>
  );
};

export default GooCoun;